//
//
//
//
//
//
//

/* eslint-disable */
import wx from 'weixin-js-sdk';
import axios from 'axios';
import POSTER from '@/assets/json/product/index';
import Painter from '@/components/painter4';

const DEFAULT_APPID = 'wx60ced1c8de7908d5'
export default {
	metaInfo () {
      return {
			title: '海报制作',
		}
	},
	components: {
		Painter,
	},
	data: ()=> ({
		jsonArray: null,
		poster_id: 0,
		shop_id: 0,
		shopInfo: null,
		id: null,
        appId: '',
        product_id:0,
        productInfo:null,
		price_type:'',
		posterKey: '',
		flightPlan: ''
	}),
	created: async function() {
		this.posterKey = new Number(new Date().getTime()).toString()
		let _this = this
		let {shop_id = 0, product_id = 0, poster_id = 0} = this.$route.params,
			{appId = 'wx60ced1c8de7908d5'} = this.$route.query
		if(shop_id) {
			this.shop_id =  shop_id
		}
		if(poster_id) {
			this.poster_id = poster_id
		}
		if(appId) {
			this.appId = appId
        }
        if(product_id) {
			this.product_id = product_id
        }
	},
	mounted: async function() {

		if(this.shop_id) {
			await this.loadShopInfo()
        }
        if(this.product_id) {
			await this.loadProductInfo()
		}
		if(this.poster_id) {
			this.buildCustomJSON()
		}
	},
	methods: {
        getRequest: function(url){
			return new Promise((resolve, reject)=> {
				axios.get(url)
				.then(res=> {
					resolve(res)
				}).catch(err=> {
					console.log(err)
				})
			})
        },
        _getMinPriceFromProduct(p) {
			let min = 9999.00
			if(p.price_type == 1) {
				let {q1 = 0, q2 = 0, q3 = 0, q4 = 0, q5 = 0} = p
				q1 = parseFloat(q1)
				q2 = parseFloat(q2)
				q3 = parseFloat(q3)
				q4 = parseFloat(q4)
				q5 = parseFloat(q5)
				if (!!q5 && q5 < min) {
					min = q5
				}
				if (!!q4 && q4 < min) {
					min = q4
				}
				if (!!q3 && q3 < min) {
					min = q3
				}
				if (!!q2 && q2 < min) {
					min = q2
				}
				if (!!q1 && q1 < min) {
					min = q1
				}
				return min
			}

		},
		loadShopInfo: async function(){
			let url='/api-mall/agent/' + this.shop_id,
				res = await this.getRequest(url)
			if(!!res){
				this.shopInfo = res.data
			}
		},
        loadProductInfo: async function(){
			let url='/api-mall/product/' + this.product_id,
				res = await this.getRequest(url)
			if(!!res){
				this.productInfo = res.data
				this.minPrice=this._getMinPriceFromProduct(this.productInfo)
				if(this.productInfo.type==1){
					this.flightPlan=!!this.productInfo.flight_plan ? this.productInfo.flight_plan.split('').join('/') : ''
				}else{
					await this.loadFlightInfo()
				}
			}
        },
        loadFlightInfo: async function(){
			let url='/api-mall/proxy/yctop/flights/list?freightRoute=' + this.productInfo.route+'&productId='+this.productInfo.id_at_yctop,
				res = await this.getRequest(url)
			if(!!res){
				!!res.data.data && res.data.data.first && res.data.data.first.length>0 && ( this.flightMsg=res.data.data.first[0] )
				let flight_plan=!!res.data.data && res.data.data.first && res.data.data.first.length>0?res.data.data.first[0].flightPlan:''
				this.flightPlan=!!flight_plan ? flight_plan.split('').join('/'):''
			}
		},
		buildCustomJSON:  function() {
			let demoJSON = POSTER[parseInt(this.poster_id)],
				shopId = this.product_id,
				productInfo = this.productInfo,
				shopInfo= this.shopInfo,
				jsonArray = []
				if(productInfo.price_type==0){
					demoJSON = demoJSON.filter(item => item.id !='min-txt');
				}

			for (let i = 0; i < demoJSON.length; i++) {
				const element = demoJSON[i],
					elementId = element.id || ''
				if (elementId == 'title') {
					element.text = shopInfo.name ?  shopInfo.name : '点击修改名称'
					if(element.css.breakWords && this.poster_id ==4 ){
						let leftText = ''
						for (let g = 0; g < element.text.length; g++) {
							leftText += `${element.text[g]}\n`;
						}
						element.text=leftText
					}
					if(this.poster_id ==6 || this.poster_id ==10 || this.poster_id ==12){
						element.text.length>0 && element.text.length<=4 && ( element.css.left=element.css.left+(4-element.text.length)*6 )
						element.text.length>4 && ( element.css.left=element.css.left-(element.text.length-4)*6 )
					}
				}
				if (elementId == 'product-title') {
					if(element.css.breakWords && this.poster_id ==1 ){
						let leftText = ''
						for (let g = 0; g < productInfo.title.length; g++) {
							leftText += `${productInfo.title[g]}\n`;
						}
						if(!!element.productTitle){
							element.css.top=550 -leftText.length*9
						}
						element.text=leftText
					}else if(this.poster_id==2 && element.text.length>9){
						let text=element.text.substring(0,9)+'\n'+element.text.substring(9,element.text.length)
						element.text=text
						element.css.top=element.css.top-36
					}else if(this.poster_id==8){
						element.text = '「'+productInfo.title+'」'
					}else{
						element.text = productInfo.title
					}
						
				}
				if (elementId == 'port') {
					element.text = productInfo.dep+'-'+ productInfo.des
				}
				if (elementId == 'dep') {
					element.text = productInfo.dep
				}
				if (elementId == 'des') {
					element.text = productInfo.des
				}
				if (elementId == 'airline') {
					element.text = !!productInfo.airline ? productInfo.airline : '-'
				}
				if(elementId == 'flightPlan'){
					if (this.poster_id ==1 || this.poster_id ==4 || this.poster_id ==6 || this.poster_id ==12 ) {
						element.text = ' '+ (!!productInfo.airline ? productInfo.airline : '-')+'   '+ ( !!this.flightPlan ? 'D'+this.flightPlan+' ':'-')
					}else if(this.poster_id ==2 || this.poster_id==9 ){
						element.text =  !!this.flightPlan ? 'D'+this.flightPlan:'-'
					}else if(this.poster_id==3){
						element.text =  (!!productInfo.airline ? productInfo.airline : '-')+'   班次：'+( !!this.flightPlan ?'D'+this.flightPlan:'-' )
					}else if(this.poster_id ==5){
						element.text =  !!this.flightPlan ? this.flightPlan:'-'
					}else if(this.poster_id==7 || this.poster_id==8 || this.poster_id==11){
						element.text = !!this.flightPlan ? '每周'+this.flightPlan : '-'
					}
				}
				
				if(this.poster_id ==10 && elementId == 'circle' ){
					let flight_plan=this.flightPlan && this.flightPlan!='-' ? this.flightPlan.replace(/\//g, ''):''
					let leftArr=[50, 83, 118, 158, 193, 229, 270]
					if(!!flight_plan){
						for (const char of flight_plan) {
							let value= char==7?leftArr[0]:leftArr[char]
							jsonArray.push({
								type: 'image',
								url: 'https://doc.efreight.cn/weapp/zjhy/static/2021-2-1/poster10-3.png',
								css: {
									top: 314,
									left: value,
									width: 36,
									height: 33,
								},
							})
						}
					}
				}
				if(this.poster_id ==13 ){
					if(elementId == 'circle'){
						let flight_plan= (this.flightPlan && this.flightPlan!='-') ? this.flightPlan.replace(/\//g, ''):''
						let leftArr=[50, 83, 118, 158, 193, 229, 270]
						if(!!flight_plan){
							for (const char of flight_plan) {
								let value= char==7?leftArr[0]:leftArr[char]
								debugger
								value && jsonArray.push({
									type: 'image',
									url: 'https://doc.efreight.cn/weapp/zjhy/static/2021-3-26/pro-poster13-3.png',
									css: {
										top: 291,
										left: value,
										width: 36,
										height: 33,
									},
								})
							}
						}
					}
					if(elementId == 'time'){
						element.text =  !!productInfo.et ? productInfo.et.substring(5,10):''
					}
					if(elementId =='title-box'){
						let length =!!shopInfo.name?shopInfo.name.length: 6
						element.css.width=21*length
					}
				}
				if (elementId == 'flightNo') {
					element.text = productInfo.type==1 ? (!!productInfo && !!productInfo.flight_no? productInfo.flight_no :'-'): (!!this.flightMsg && !!this.flightMsg.flightNo ? this.flightMsg.flightNo :'-')
				}
				if (elementId == 'flightType') {
					element.text = productInfo.type==1 ? (!!productInfo && !!productInfo.flight_type ? productInfo.flight_type :'-') : (!!this.flightMsg && !!this.flightMsg.flightNo? this.flightMsg.flightModel :'-')
				}
				if (elementId == 'ETD') {
					element.text = productInfo.type==1 ? '-': (!!this.flightMsg && !!this.flightMsg.etd ? this.flightMsg.etd :'-')
				}
				if (elementId == 'ETA') {
					element.text = productInfo.type==1 ? '-': (!!this.flightMsg && !!this.flightMsg.eta ? this.flightMsg.eta :'-')
				}
				if (elementId == 'min-price') {
					element.text = productInfo.price_type==1 ? ''+ this.minPrice : productInfo.price_remark
					if(productInfo.price_type==0 && this.poster_id !=2 && this.poster_id !=4 && this.poster_id !=6 && this.poster_id !=12 && this.poster_id != 14){
						element.css.originX='center'
					}else if(productInfo.price_type==0 && this.poster_id ==4){
						element.css.left=26
					}else if(productInfo.price_type==0 && this.poster_id ==6 || productInfo.price_type==0 && this.poster_id ==12){
						element.css.left=45
						element.css.fontSize=38
					} else if(productInfo.price_type == 1 && this.poster_id == 14) {
						jsonArray.push({
							type: 'text',
							text: '仅需',
							css: {
								top: 580,
								left: 30,
								color: '#fff',
								fontSize: 16,
							}
						})
						element.css.left += 35
					}
				}
				if (elementId == 'avatar') {
					element.url = 'https://' + (shopInfo.avatar ? shopInfo.avatar : 'doc.efreight.cn/weapp/zjhy/static/2020-12-15/default-logo.png')
					if(this.poster_id ==6 || this.poster_id ==10 || this.poster_id ==12){
						!!shopInfo.name && shopInfo.name.length>0 && shopInfo.name.length<=4 && ( element.css.left=element.css.left+(4-shopInfo.name.length)*6 )
						!!shopInfo.name && shopInfo.name.length>4 && ( element.css.left=element.css.left-(shopInfo.name.length-4)*6 )
					}
				}
				if (elementId == 'qrcode') {
					element.content = 'http://agent-mall.efreight.cn/product/' + productInfo.agent_id+'/'+ productInfo.id + '?posterId=' + this.id + '&posterKey=' + this.posterKey
				}
				jsonArray.push(element)
			}
			this.jsonArray = jsonArray
		},


	}
}
